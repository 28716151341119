import { Link, withPrefix } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import './all.css';
import ContainerStyles from './ContainerStyles';
import ExternalLinks from './ExternalLinks';
import useSiteMetadata from './SiteMetadata';

const isPartiallyCurrent = ({ isPartiallyCurrent }) =>
  isPartiallyCurrent
    ? {
        className: 'underline link text-color dim f4 dib mr3',
      }
    : {
        className: 'link dim text-color f4 dib mr3',
      };

// TODO: correctly handle CI environments for aws amplify with aws-exports
// https://github.com/aws-amplify/amplify-cli/issues/3643
const getSiteLinks = () => (
  <>
    <Link getProps={isPartiallyCurrent} to="/music">
      music
    </Link>
    <Link getProps={isPartiallyCurrent} to="/shows">
      shows
    </Link>
    <Link getProps={isPartiallyCurrent} to="/about">
      about
    </Link>
  </>
);

// 3. Add icons to individual releasse page with links to external sites
// 4. On release page, change date format to Month, Day Year, get rid of "Released On" prefix
// 5. Fix icons for mobile menu

// TODO: https://www.gatsbyjs.com/docs/add-seo-component/
const TemplateWrapper = ({ children }) => {
  const {
    title,
    description,
    contactEmail,
    facebookURL,
    instagramURL,
    spotifyURL,
    bandcampURL,
    itunesURL,
    siteUrl,
  } = useSiteMetadata();
  return (
    <ContainerStyles>
      <Helmet>
        <html lang="en" className="bg-black min-vh-100" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="og:description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${siteUrl}${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <meta name="googlebot" content="nosnippet" />
        <body className="min-vh-100 text-color" />
      </Helmet>
      <div className="flex items-center">
        <nav className="pa4 w-100 flex">
          <Link className="thearcher link dim f3 dib mr3 nowrap" to="/">
            Bobby De Vay
          </Link>
          <div className="db-l dn">{getSiteLinks()}</div>
        </nav>
        <nav className="pa4 flex-l dn">
          {ExternalLinks({
            instagramURL,
            spotifyURL,
            facebookURL,
            bandcampURL,
            itunesURL,
          })}
        </nav>
        <div className="db dn-l absolute MobileMenu__container mv4">
          <input
            type="checkbox"
            name="menu"
            id="menu"
            className="MobileMenu__checkbox link no-underline dim pa2 mh4"
          />
          <label
            className="f4 MobileMenu__label text-color pa2 bg-black dim pointer mh4"
            htmlFor="menu"
          >
            menu
          </label>
          <nav className="MobileMenu__nav items-center pa4 border-box dn">
            <>
              {getSiteLinks()}
              {ExternalLinks({
                instagramURL,
                spotifyURL,
                facebookURL,
                bandcampURL,
                itunesURL,
              })}
            </>
          </nav>
        </div>
      </div>
      <div className="pa4 flex-auto">{children}</div>
      <div className="pa4 flex justify-end">
        <a
          className="f4 thearcher link dim dib"
          target="_blank"
          title={contactEmail}
          href={`mailto:${contactEmail}`}
        >
          say hello
        </a>
      </div>
    </ContainerStyles>
  );
};

export default TemplateWrapper;
