import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
  const { site, settingsJson } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
        settingsJson {
          contactEmail
          description
          title
          facebookURL
          instagramURL
          spotifyURL
          bandcampURL
          itunesURL
        }
      }
    `
  );
  return {
    title: settingsJson.title || site.siteMetadata.title,
    siteUrl: site.siteMetadata.siteUrl,
    contactEmail: settingsJson.contactEmail,
    description: settingsJson.description || site.siteMetadata.description,
    facebookURL: settingsJson.facebookURL || site.siteMetadata.facebookURL,
    instagramURL: settingsJson.instagramURL || site.siteMetadata.instagramURL,
    spotifyURL: settingsJson.spotifyURL || site.siteMetadata.spotifyURL,
    bandcampURL: settingsJson.bandcampURL || site.siteMetadata.bandcampURL,
    itunesURL: settingsJson.itunesURL || site.siteMetadata.itunesURL,
  };
};

export default useSiteMetadata;
