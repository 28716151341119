import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

export default function ExternalLinks({
  facebookURL,
  spotifyURL,
  instagramURL,
  bandcampURL,
  itunesURL,
}) {
  const { appleLogo, instaLogo, spotifyLogo, bandcampLogo } = useStaticQuery(
    graphql`
      query LogosQuery {
        appleLogo: file(relativePath: { eq: "social/apple_logo_v2.png" }) {
          childImageSharp {
            fluid(maxWidth: 128) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        instaLogo: file(relativePath: { eq: "social/instagram_logo_v2.png" }) {
          childImageSharp {
            fluid(maxWidth: 128) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        spotifyLogo: file(relativePath: { eq: "social/spotify_logo_v2.png" }) {
          childImageSharp {
            fluid(maxWidth: 128) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        bandcampLogo: file(
          relativePath: { eq: "social/bandcamp_logo_v1.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 128) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  );
  return (
    <>
      {spotifyURL && (
        <a
          className="link text-color dim f4 dib mr3 w3 h3 ExternalLink"
          target="_blank"
          href={spotifyURL}
        >
          <Img title="spotify" fluid={spotifyLogo.childImageSharp.fluid} />
        </a>
      )}
      {itunesURL && (
        <a
          className="link text-color dim f4 dib mr3 w3 h3 ExternalLink"
          target="_blank"
          href={itunesURL}
        >
          <Img title="iTunes" fluid={appleLogo.childImageSharp.fluid} />
        </a>
      )}
      {bandcampURL && (
        <a
          className="link text-color dim f4 dib mr3 w3 h3 ExternalLink"
          target="_blank"
          href={bandcampURL}
        >
          <Img title="bandcamp" fluid={bandcampLogo.childImageSharp.fluid} />
        </a>
      )}
      {instagramURL && (
        <a
          className="link text-color dim f4 dib mr3 w3 h3 ExternalLink"
          target="_blank"
          href={instagramURL}
        >
          <Img title="instagram" fluid={instaLogo.childImageSharp.fluid} />
        </a>
      )}
      {/*facebookURL && (
      <a
        className="link text-color dim f4 dib mr3"
        target="_blank"
        href={facebookURL}
      >
        facebook
      </a>
    )*/}
    </>
  );
}
